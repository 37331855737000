import {
  EditorReadyFn,
  FlowEditorSDK,
  FlowPlatformOptions,
  GetAppManifestFn,
} from '@wix/yoshi-flow-editor';
import { BM_PLAYGROUND, WIDGET_ID } from './constants';

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefinitionId,
  platformOptions,
  flowAPI,
) => {};

export const getAppManifest: GetAppManifestFn = async (
  { appManifestBuilder },
  editorSDK,
  contextParams,
  flowAPI,
) => {
  const { t } = flowAPI.translations;

  appManifestBuilder.configureWidget(WIDGET_ID, (widgetBuilder) => {
    widgetBuilder.gfpp().set('mainAction2', {
      label: t('app.settings.gfpp.test'),
      onClick: ({ detail }) => {
        editorSDK.editor.openDashboardPanel('', {
          url: BM_PLAYGROUND,
          closeOtherPanels: true,
        });
      },
    });

    widgetBuilder.behavior().set({
      canReparent: false,
      duplicatable: false,
      pinnable: false,
      closable: false,
      removable: false,
      resizable: false,
      toggleShowOnAllPagesEnabled: false,
      rotatable: false,
    });
  });

  return appManifestBuilder.build();
};

export const exports = async (
  editorSDK: FlowEditorSDK,
  { initialAppData }: FlowPlatformOptions,
) => {
  const comp = (
    await editorSDK.document.tpa.app.getAllCompsByApplicationId(
      'token',
      initialAppData.applicationId,
    )
  ).at(0);

  const componentRef = comp
    ? await editorSDK.document.components.getById('token', {
        id: comp.id,
      })
    : undefined;

  return {
    private: {
      setPosition: async (position: 'left' | 'right') => {
        if (!componentRef) {
          return;
        }

        await editorSDK.components.properties.update('token', {
          componentRef,
          props: {
            placement: `BOTTOM_${position.toUpperCase()}`,
          },
        });
      },

      getPosition: async () => {
        if (!componentRef) {
          return;
        }

        const properties = await editorSDK.components.properties.get('token', {
          componentRef,
        });

        return properties?.placement;
      },
    },
  };
};
